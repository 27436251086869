import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward, faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import DropArrow from "../UI/DropArrow/DropArrow";
import "./About.scss";

const About = () => {
  return (
    <section id="About">
      <p className="about__text">Get To Know More</p>
      <h1 className="about__section">About Me</h1>
      <div className="about__container">
        <div className="about__pictureContainer">
          <img src={`${process.env.PUBLIC_URL}/img/ahmedKhemici2.jpg`} alt="" />
        </div>
        <div className="about__detailContainer">
          <div className="about__detailContainers">
            <div className="about__details">
              <FontAwesomeIcon icon={faAward} />
              <h3>Experience</h3>
              <p>
                4+ years <br />
                Web Development
              </p>
            </div>
            <div className="about__details">
              <FontAwesomeIcon icon={faGraduationCap} />
              <h3>Education</h3>
              <p>
                B.Sc. Bachelors Degree
                <br />
                M.Sc. Bachelors Degree
              </p>
            </div>
          </div>
          <div className="about__description">
            <p>
              Experienced Backend Developer & DevOps enthusiast with 4 years in
              a dynamic startup. Proficient in Python, adaptable to various
              technologies like Node.js, React.js, AWS, Azure, & Ansible.
              Skilled in fostering innovation through effective DevOps
              practices, enhancing productivity. Recognized for collaborative
              problem-solving & willingness to learn.
            </p>
          </div>
        </div>
      </div>
      <DropArrow next="#Skill" />
    </section>
  );
};

export default About;
