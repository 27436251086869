import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import './Contact.scss'

const Contact = () => {
    return (
        <section id='Contact'>
            <p className="contact__text">Get in Touch</p>
            <h1 className="contact__section">Contact Me</h1>
            <div className="contact__container">
                <a href='mailto:ahmed.khemici1@gmail.com' className="contact__media">
                        <FontAwesomeIcon icon={faEnvelope} />
                        <h3>ahmed.khemici1@gmail.com</h3>
                </a>
                <a href='https://linkedin.com/in/ahmed-khemici-4523aa156' className="contact__media">
                    <FontAwesomeIcon icon={faLinkedin}/>
                    <h3>LinkedIn</h3>
                </a>
            </div>
        </section>
    )
}

export default Contact
