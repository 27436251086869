import './Footer.scss'

const Footer = () => {
    return (
        <footer className='Footer'>
            Copyright 2023 Ahmed Khemici. All Rights Reserved
        </footer>
    )
}

export default Footer
