import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithub} from '@fortawesome/free-brands-svg-icons'
import Button from '../UI/Button/Button';
import './Profile.scss';

const Profile = () => {
    const CVHandler = () =>{
        window.open(`${process.env.PUBLIC_URL}/pdf/AhmedKhemiciCV.pdf`)
    }
    const ContactHandler = () => {
        window.location.href = '#Contact'
    }
    return (
        <section id="Profile">
            <div className="profile__container">
            <div className="profile__pictureContainer">
                <img src={`${process.env.PUBLIC_URL}/img/ahmedKhemici.jpg`} alt="" />
            </div>
            <div className="profile__description">
                <p className="profile__text">Hi im</p>
                <h1 className="profile__name">Ahmed Khemici</h1>
                <p className="profile__job">Full-Stack Developer</p>
                <div className="profile__info">
                    <Button className='buttonTypeOne' text='Download CV' onClick={CVHandler}/>
                    <Button className='buttonTypeTwo' text='Contact Info' onClick={ContactHandler}/>
                </div>
                <div className="profile__social">
                    <a href="https://linkedin.com/in/ahmed-khemici-4523aa156" alt="My LinkedIn Profile">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a href="https://github.com/AhmedKhemici" alt="My GitHub Profile">
                        <FontAwesomeIcon icon={faGithub}/>
                    </a>
                </div>
            </div>
            </div>
        </section>
    )
}

export default Profile
