import { useState, useEffect } from 'react';
import HamburgerButton from './HamburgerNavBar/HamburgerButton.js'
import HamburgerMenu from './HamburgerNavBar/HamburgerMenu.js'
import './NavigationBar.scss';

const NavigationBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(null);

    useEffect(()=>{
        if (isMenuOpen === false) {
            setTimeout(() => {
                setIsMenuOpen(null);
            }, 500);
        }
    },[isMenuOpen]); 
    useEffect(() => {    
        window.addEventListener('resize', () => {
            if(window.innerWidth > '768'){
                setIsMenuOpen(null);
            };
        });
      }, []);

    const hamburgerMenuHandler = () => {
        if (isMenuOpen === null) setIsMenuOpen(false)
        setIsMenuOpen((old_state) => !old_state);
    }
    const hamburgerMenu = "navigationBar__hamburgerMenu "+[isMenuOpen === null ? "" : isMenuOpen ? "openDrawer":"closeDrawer"];
    const hamburgerButton = isMenuOpen === null ? ["","",""] : isMenuOpen ? ["openDrawerSpan1","openDrawerSpan2","openDrawerSpan3"]:["closeDrawerSpan1","closeDrawerSpan2","closeDrawerSpan3"];
    return ( 
        <nav>
            <div className="NavigationBar">
                <div className="navigationBar__logo">
                    <a href="#Profile">Ahmed Khemici</a></div>
                <div className="navigationBar__menu">
                    <ul className="navigationBar__links">
                        <li><a href="#About">About</a></li>
                        <li><a href="#Skill">Skills</a></li>
                        {/* <li><a href="#Project">Projects</a></li> */}
                        <li><a href="#Contact">Contact</a></li>
                    </ul>
                </div>
                <HamburgerButton hamburgerMenuHandler={hamburgerMenuHandler} hamburgerButton={hamburgerButton}/>
            </div>
            <HamburgerMenu className={hamburgerMenu} />
        </nav>
    )
}

export default NavigationBar
