import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import DropArrow from "../UI/DropArrow/DropArrow";
import "./Skill.scss";

const Skill = () => {
  const data = {
    backend: [
      { tool: "NodeJs", level: "Experienced", icon: faCircleCheck },
      { tool: "Flask", level: "Experienced", icon: faCircleCheck },
      { tool: "Spring boot", level: "Intermediate", icon: faCircleCheck },
      { tool: "NestJs", level: "Experienced", icon: faCircleCheck },
    ],
    frontend: [
      { tool: "HTML", level: "Experienced", icon: faCircleCheck },
      { tool: "CSS", level: "Experienced", icon: faCircleCheck },
      { tool: "Javascript", level: "Experienced", icon: faCircleCheck },
      { tool: "ReactJs", level: "Experienced", icon: faCircleCheck },
      { tool: "NextJs", level: "Intermediate", icon: faCircleCheck },
      { tool: "ReactNative", level: "Intermediate", icon: faCircleCheck },
    ],
    devops: [
      { tool: "Linux", level: "Experienced", icon: faCircleCheck },
      { tool: "Aws", level: "Intermediate", icon: faCircleCheck },
      { tool: "Azure", level: "Intermediate", icon: faCircleCheck },
      { tool: "Ansible", level: "Experienced", icon: faCircleCheck },
      { tool: "CI CD", level: "Intermediate", icon: faCircleCheck },
      { tool: "Firebase", level: "Intermediate", icon: faCircleCheck },
    ],
  };
  const skill = (type) => {
    return data[type].map((exp) => {
      return (
        <article>
          <div className="skill__icon">
            <FontAwesomeIcon icon={exp.icon} />
          </div>
          <div className="skill__title">
            <h3>{exp.tool}</h3>
            <p>{exp.level}</p>
          </div>
        </article>
      );
    });
  };
  return (
    <section id="Skill">
      <p className="skill__text">Explore My</p>
      <h1 className="skill__section">Skills</h1>
      <div className="skill__container">
        <div className="skill__filed">
          <p className="skill__filedTitle">Backend Development</p>
          <div className="skill__experiences">{skill("backend")}</div>
        </div>
        <div className="skill__filed">
          <p className="skill__filedTitle">Frontend Development</p>
          <div className="skill__experiences">{skill("frontend")}</div>
        </div>
        <div className="skill__filed">
          <p className="skill__filedTitle">Devops</p>
          <div className="skill__experiences">{skill("devops")}</div>
        </div>
      </div>
      <DropArrow next="#Contact" />
    </section>
  );
};

export default Skill;
