import React from 'react'
import './HamburgerMenu.scss'

const HamburgerMenu = (props) => {
  return (
    <div className={props.className}>
        <ul className="navigationBar__hamburgerLinks">
            <li><a href="#About">About</a></li>
            <li><a href="#Skill">Skill</a></li>
            {/* <li><a href="#Project">Projects</a></li> */}
            <li><a href="#Contact">Contact</a></li>
        </ul>
    </div>
  )
}

export default HamburgerMenu
