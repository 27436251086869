import React from 'react'
import './HamburgerButton.scss'

const HamburgerButton = (props) => {
  return (
    <button className="navigationBar__hamburgerIcon" onClick={props.hamburgerMenuHandler}>
        <span className={props.hamburgerButton[0]}></span>
        <span className={props.hamburgerButton[1]}></span>
        <span className={props.hamburgerButton[2]}></span>
    </button>
  )
}

export default HamburgerButton
