import './DropArrow.scss'

const DropArrow = (props) => {
  const arrows = <div className='downArrowWing'>
                    <span className='leftWing' />
                    <span className='rightWing' />
                </div>
  return (
    <div className="downArrow">
      <a href={props.next}>
        {arrows}
        {arrows}
      </a>
    </div>
  )
}

export default DropArrow
