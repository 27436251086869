import "./App.css";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import Profile from "./components/Profile/Profile";
import About from "./components/About/About";
import Skill from "./components/Skill/Skill";
// import Project from "./components/Project/Project";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <NavigationBar />
      <Profile />
      <About />
      <Skill />
      {/* <Project /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
